<template>
  <div class="p-3">
    <b-card class="border-none shadow-sm" no-body>
      <div class="bg-gray p-3">
        <b-row>
          <b-col cols="6">
            <div
              class="d-inline-flex w-100 align-items-center"
              @click="$router.push('/member/' + profile.member_level_id)"
            >
              <div class="member-tier-icon">
                <font-awesome-icon :icon="['fas', 'crown']" />
              </div>
              <div>
                <span class="f-sm">ระดับสมาชิก</span>
                <!-- @click="checkIsBranchPoint" -->
                <font-awesome-icon
                  :icon="['fas', 'chevron-right']"
                  class="highlight-color cursor-pointer f-sm ml-2"
                />
                <div class="cursor-pointer f-bold">
                  <!-- @click="$router.push('/member/' + profile.member_level_id)" -->
                  {{ profile.display }}
                </div>
              </div>
            </div>
          </b-col>
          <b-col cols="6">
            <div
              class="d-inline-flex w-100 align-items-center justify-content-end"
              @click="$router.push('/marketplace')"
            >
              <div class="f-sm">
                คะแนนสะสมปัจจุบัน:
                <u class="ml-2 highlight-color f-xs">
                  <font-awesome-icon
                    :icon="['fas', 'plus']"
                    class="mr-1"
                  />เพิ่ม</u
                >
              </div>
            </div>
            <div class="f-bold highlight-color text-right">
              <font-awesome-icon :icon="['fas', 'gift']" class="f-sm mr-2" />{{
                profile.total_point | numeral("0,0")
              }}
            </div>
          </b-col>
        </b-row>
        <template v-if="layoutDisplay == 'Tier'">
          <div class="reletive">
            <b-progress
              class="mt-3 mb-1"
              height="10px"
              :value="profile.total_spend"
              :max="
                memberLevel.upgrade_tier_id != 0
                  ? memberLevel.upgrade_baht
                  : memberLevel.maintain_baht
              "
              v-if="profile.expired_date || memberLevel.upgrade_tier_id != 0"
            >
            </b-progress>
            <div
              class="range-avg"
              id="range-maintain"
              v-if="
                memberLevel.maintain_baht > 0 &&
                ((memberLevel.upgrade_tier_id != 0 && profile.expired_date) ||
                  (memberLevel.upgrade_tier_id != 0 && !profile.expired_date))
              "
              :data-avg="memberLevel.maintain_baht"
            ></div>
          </div>
          <div
            class="d-inline-flex w-100 f-sm"
            v-if="profile.expired_date || memberLevel.upgrade_tier_id != 0"
          >
            <div>
              สะสมยอดเพิ่ม{{
                memberLevel.upgrade_tier_id
                  ? `เพื่ออัปเกรดเป็น ${memberLevel.upgrade_tier_name}`
                  : "เพื่อรับสถานะ " + profile.display
              }}
            </div>
            <div class="ml-auto">
              <span class="highlight-color f-bold"
                >{{ profile.total_spend | numeral("0,0") }}/{{
                  memberLevel.upgrade_tier_id != 0
                    ? memberLevel.upgrade_baht
                    : memberLevel.maintain_baht | numeral("0,0")
                }}</span
              >
              บาท
            </div>
          </div>
          <div class="d-inline-flex w-100 f-sm" v-if="profile.expired_date">
            <div>ระดับสมาชิกจะหมดอายุภายใน</div>
            <div class="ml-auto f-bold text-danger">
              {{
                $moment(profile.expired_date)
                  .add(543, "y")
                  .format("DD MMM YYYY")
              }}
              <font-awesome-icon
                id="tooltip-info"
                :icon="['fas', 'info-circle']"
                class="f-sm ml-2"
              /><b-tooltip
                target="tooltip-info"
                triggers="click"
                custom-class="tooltip-info"
              >
                <div class="content-between">
                  <div>ระดับสมาชิกจะหมดอายุภายใน</div>
                  <div class="text-danger f-bold">
                    {{
                      $moment(profile.expired_date)
                        .add(543, "y")
                        .format("DD MMM YYYY")
                    }}
                  </div>
                </div>
                <div class="content-between">
                  <div>
                    สะสมยอดเพิ่ม{{
                      memberLevel.upgrade_tier_id
                        ? `เพื่ออัปเกรด`
                        : "เพื่อรักษาระดับคะแนน"
                    }}
                  </div>
                  <div
                    class="text-danger f-bold"
                    v-if="memberLevel.upgrade_tier_id != 0"
                  >
                    {{
                      (memberLevel.upgrade_baht - profile.total_spend)
                        | numeral("0,0")
                    }}
                    บาท
                  </div>
                  <div v-else class="text-danger f-bold">
                    {{
                      (memberLevel.maintain_baht - profile.total_spend)
                        | numeral("0,0")
                    }}
                    บาท
                  </div>
                </div>
              </b-tooltip>
            </div>
          </div>
        </template>
      </div>
      <div v-html="memberLevel.description" class="p-3"></div
    ></b-card>
    <Footer text="กลับไปหน้าหลัก" @onClick="$router.replace('/profile')" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      memberLevel: {
        id: 0,
        name: "",
        is_entry_point: 0,
        upgrade_tier_id: 0,
        upgrade_tier_name: null,
        upgrade_baht: 99999999,
        downgrade_tier_id: 0,
        downgrade_tier_name: null,
        baht_per_point: 0,
        membership_period: 0,
        expire_condition_id: 1,
        maintain_baht: 99999999,
        icon: "",
        display: "",
        description: ""
      },
      layoutDisplay: this.$store.state.theme.line_layout,
      profile: { total_spend: 0 }
    };
  },
  created() {
    // this.memberDesc();
    this.getUserProfile();
  },
  updated() {
    const rangeAvg = document.getElementById("range-maintain");
    const avgValue = rangeAvg.dataset.avg;
    let max =
      this.memberLevel.upgrade_tier_id != 0
        ? this.memberLevel.upgrade_baht
        : this.memberLevel.maintain_baht;
    let avgPosition = Number((avgValue * 100) / max);
    rangeAvg.style.left = `${avgPosition > 100 ? 100 : avgPosition}%`;
  },
  methods: {
    async getUserProfile() {
      this.$cookies.set("hourglass_register_success", true);

      await this.$store.dispatch("getUserApi");
      this.profile = this.$store.state.shortProfile;
      this.memberDesc(this.profile.member_level_id);
    },
    async memberDesc() {
      const data = await this.$axios(
        `${process.env.VUE_APP_API}/api/v1/user/getmember/${this.$route.params.id}`
      );

      this.memberLevel = data.detail;
    }
  }
};
</script>

<style>
p > img {
  width: 100%;
  height: auto;
}
p iframe {
  aspect-ratio: 1;
  width: 100%;
  height: auto;
}
.bg-gray {
  background-color: whitesmoke;
}
.progress {
  height: 10px;
  position: relative;
  border: 1px solid #c9c9c9;
  background-color: white;
  overflow: visible;
}

.progress-bar {
  border-radius: 0.25rem;
  background: var(--text-color);
}
.range-avg {
  height: 10px;
  width: 2px;

  background: var(--text-color);
  z-index: 1;
  -webkit-appearance: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(0, -50%);
}
.tooltip-info .tooltip-inner {
  background: white;
  color: #7a7a7a;
  width: 250px;
  max-width: unset;
  font-size: var(--text-xs);
}
.tooltip.b-tooltip {
  opacity: 1;
}
.bs-tooltip-top .arrow::before {
  border-top-color: white;
}
</style>
